import * as floatingUi from '@floating-ui/react';
import { FloatingDelayGroup } from '@floating-ui/react';
import React, { createContext, useMemo, useState } from 'react';

import { createContextHook } from '@/utils/hooks';

export type UseTooltipOptions = {
  /**
   * Whether to start an uncontrolled tooltip open or not.
   *
   * Ignored if tooltip is controlled.
   */
  initialOpen?: boolean;
  /**
   * Where to place the tooltip content in relation to its anchor.
   */
  placement?: floatingUi.Placement;
  /**
   * Controlled open state of the tooltip. Must be used with onOpenChange.
   */
  open?: boolean;
  /**
   * Event handler called when the open state changes.
   * @param open - The new open state
   */
  onOpenChange?: (open: boolean) => void;
  /**
   * Delay applied to the opening and closing of the tooltip when out of hover or focus.
   */
  delay?: React.ComponentProps<typeof FloatingDelayGroup>['delay'];
  /**
   * Whether the tooltip is disabled or not.
   */
  disabled?: boolean;
}

/**
 * Hook for managing the state of a tooltip.
 */
export const useTooltip = ({
  initialOpen = false,
  placement = 'top',
  open: controlledOpen,
  onOpenChange: controlledOnOpenChange,
  delay = 0,
  disabled = false,
}: UseTooltipOptions = {}) => {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = controlledOnOpenChange ?? setUncontrolledOpen;

  const data = floatingUi.useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: floatingUi.autoUpdate,
    middleware: [
      floatingUi.offset(5),
      floatingUi.flip({
        crossAxis: placement.includes('-'),
        fallbackAxisSideDirection: 'start',
        padding: 5,
      }),
      floatingUi.shift({ padding: 5 }),
    ],
  });

  const context = data.context;

  // interaction for when the trigger is hovered
  const hover = floatingUi.useHover(
    context,
    {
      move: false,
      enabled: controlledOpen == null && !disabled,
      delay
    },
  );

  // interaction for when the trigger is focus
  const focus = floatingUi.useFocus(
    context,
    { enabled: controlledOpen == null && !disabled, }
  );

  // interaction for dismissing the tooltip
  const dismiss = floatingUi.useDismiss(context);

  // interaction for managing the tooltip role.
  const role = floatingUi.useRole(context, { role: 'tooltip' });

  // wired up interactions
  const interactions = floatingUi.useInteractions([hover, focus, dismiss, role]);

  return useMemo(() => ({
    open,
    setOpen,
    ...interactions,
    ...data,
  }), [data, interactions, open, setOpen]);
};

type TooltipContextType = ReturnType<typeof useTooltip> | null;

export const TooltipContext = createContext<TooltipContextType>(null);

export const useTooltipContext = createContextHook(TooltipContext);
