import {
  getCoreRowModel,
  getExpandedRowModel, getFilteredRowModel,
  getGroupedRowModel,
  getPaginationRowModel,
  getSortedRowModel, Row,
  TableOptions
} from '@tanstack/react-table';
import { Virtualizer, VirtualizerOptions } from '@tanstack/react-virtual';
import React, { useMemo } from 'react';

import { DataTableInstance } from '@/components-new/data-table/use-data-table';

/**
 * Represents a set of options used to configure the behavior and appearance of an extended table options.
 */
export type ExtendedTableOptions<TData> = {
  /**
   * Reference object to an instance of a virtualizer specifically designed
   * for handling virtualized rendering of HTML table columns.
   */
  columnVirtualizerInstanceRef?: React.MutableRefObject<Virtualizer<HTMLDivElement, HTMLTableCellElement>>;

  /**
   * Configuration options for column virtualization within a table or similar structure.
   * The `columnVirtualizerOptions` property can be used to customize the behavior and performance
   * of the virtualizer, such as managing the rendering of columns within the viewport.
   */
  columnVirtualizerOptions?: Partial<VirtualizerOptions<HTMLDivElement, HTMLTableCellElement>>;

  /**
   * Indicates whether the component or element should use a dense layout.
   * When set to true, it applies a more compact spacing.
   */
  dense?: boolean;
  /**
   * Indicates whether column filters are enabled.
   */
  enableColumnFilters?: boolean;
  /**
   * Whether column virtualization is enabled.
   * When enabled, only the visible columns are rendered to optimize performance for large datasets.
   * Setting this property to `true` can significantly improve rendering efficiency
   * by reducing the DOM elements related to offscreen columns.
   */
  enableColumnVirtualization?: boolean;
  /**
   * Indicates whether global filter feature is enabled.
   */
  enableGlobalFilter?: boolean;
  /**
   * Indicates whether pagination functionality is enabled.
   * When set to true, pagination features are activated, allowing data or content
   * to be organized and displayed across multiple pages.
   * If false, all data or content will be displayed without pagination.
   */
  enablePagination?: boolean;
  /**
   * Whether row virtualization is enabled.
   *
   * When set to true, row virtualization optimizes the rendering performance
   * by only rendering rows currently visible in the viewport and dynamically loading
   * additional rows as needed. Setting this to false will render all rows regardless
   * of their visibility, which may impact performance for large datasets.
   */
  enableRowVirtualization?: boolean;
  /**
   * Whether the sticky header functionality is enabled.
   * If set to true, the header will remain fixed at the top of the viewport as the page is scrolled.
   * If set to false, the header will scroll out of view with the content.
   */
  enableStickyHeader?: boolean;
  /**
   * Whether to display the data table gird lines.
   */
  grid?: boolean;
  /**
   * Defines the layout mode for a user interface or component.
   * Some other features such as column resizing will set this automatically.
   *
   * Possible values:
   * - 'grid': Allows a flexible grid layout.
   * - 'grid-fixed': Defines a grid layout with fixed dimensions or constraints.
   * - 'semantic': Uses a semantic layout approach, using actual table elements.
   */
  layoutMode?: 'grid' | 'grid-fixed' | 'semantic';
  /**
   * Reference object that holds the instance of the row virtualizer.
   * Used to manage and access the virtualizer instance for rendering and interacting
   * with rows efficiently in a table or list.
   */
  rowVirtualizerInstanceRef?: React.MutableRefObject<Virtualizer<HTMLDivElement, HTMLTableRowElement>>;
  /**
   * Options to configure the behavior of row virtualization.
   * This property allows for customization of virtualization
   * within a table or a list, optimizing rendering performance
   * by only drawing the rows currently in the viewport.
   */
  rowVirtualizerOptions?: Partial<VirtualizerOptions<HTMLDivElement, Element>>;
  /**
   * Whether to display the aggregate group row in a data table or grid.
   * If set to `true`, the aggregate group row will be shown,
   * which typically contains summarized or calculated data for grouped rows.
   */
  showAggregateGroupRow?: boolean;
  /**
   * Whether the table rows should be stripped.
   */
  striped?: boolean;
  /**
   * A function that determines additional properties for a table body row.
   */
  tableBodyRowProps?: ({ table, row }: { table: DataTableInstance<TData>, row: Row<TData> }) => Record<string, any>;
};

/**
 * Defines the configuration options for the DataTable instance. It is a comprehensive type that combines multiple table-related options and functionalities.
 */
export type DataTableOptions<TData> = {
  getCoreRowModel?: TableOptions<TData>['getCoreRowModel'];
} & ExtendedTableOptions<TData>
  & Omit<TableOptions<TData>, 'getCoreRowModel'>;

export type ConfiguredTableOptions<TData> = ExtendedTableOptions<TData> & TableOptions<TData>;

/**
 * Default settings for a column size.
 * It includes properties for the minimum size, maximum size, and default size of a column.
 */
export const DEFAULT_COLUMN = {
  /**
   * The minimum width of a column in pixels.
   */
  minSize: 40,
  /**
   * The maximum width of a column in pixels.
   */
  maxSize: 1000,
  /**
   * The default width of a column in pixels.
   */
  size: 180,
};


/**
 * Configures and returns a set of options for a Data Table instance.
 */
export const useDataTableOptions = <TData>({
  columnResizeMode = 'onChange',
  columnResizeDirection = 'ltr',
  defaultColumn,
  enableColumnResizing = false,
  enableStickyHeader = false,
  enableSorting = false,
  enablePagination = false,
  enableGrouping = false,
  enableColumnVirtualization,
  enableRowVirtualization,
  enableExpanding = false,
  enableGlobalFilter = false,
  enableColumnFilters = false,
  layoutMode,
  ...rest
}: DataTableOptions<TData>): ConfiguredTableOptions<TData> => {
  layoutMode = layoutMode || (enableColumnResizing ? 'grid-fixed' : 'semantic');

  // determine layout based on other enabled features
  if (layoutMode === 'semantic' && (enableColumnVirtualization || enableRowVirtualization)) {
    layoutMode = 'grid';
  }

  // automatically enable sticky header when row virtualization is enabled
  if (enableRowVirtualization) {
    enableStickyHeader = true;
  }

  defaultColumn = useMemo(() => ({ ...DEFAULT_COLUMN, ...defaultColumn }), [defaultColumn]);

  // set once and never updated, row virtualization toggle is not supported
  [enableColumnVirtualization, enableRowVirtualization] = useMemo(() => ([
    enableColumnVirtualization,
    enableRowVirtualization,
  ]), []);

  return {
    columnResizeMode,
    columnResizeDirection,
    defaultColumn,
    enableColumnResizing,
    enableColumnVirtualization,
    enableRowVirtualization,
    enableSorting,
    enablePagination,
    enableStickyHeader,
    enableGrouping,
    layoutMode,
    enableColumnFilters,
    enableGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: enableSorting ? getSortedRowModel() : undefined,
    getPaginationRowModel: enablePagination ? getPaginationRowModel() : undefined,
    getGroupedRowModel: enableGrouping ? getGroupedRowModel() : undefined,
    getExpandedRowModel: enableExpanding || enableGrouping ? getExpandedRowModel() : undefined,
    getFilteredRowModel: enableColumnFilters || enableGlobalFilter ? getFilteredRowModel() : undefined,
    ...rest,
  };
};
