import { RowData } from '@tanstack/react-table';
import React, { useMemo } from 'react';

import { DataTableBody, MemoDataTableBody } from '@/components-new/data-table/data-table-body';
import { DataTableHead } from '@/components-new/data-table/data-table-head';
import { DataTableInstance } from '@/components-new/data-table/use-data-table';
import { useDataTableColumnVirtualizer } from '@/components-new/data-table/use-data-table-column-virtualizer';
import { Table } from '@/components-new/table';
import { parseCssVarName } from '@/utils/parsing';


/**
 * Renders a data table with customizable features such as column sizing,
 * layout modes, dense or grid styling, striped rows, and sticky headers.
 * It manages virtualized columns for efficient performance
 * and provides a dynamic user interface for handling large datasets.
 */
export const DataTable = <TData extends RowData>({ table }: { table: DataTableInstance<TData>}) => {
  const {
    getFlatHeaders,
    getState,
    options: {
      columns,
      layoutMode,
      dense,
      grid,
      striped,
      enableStickyHeader,
    },
    refs: { tableContainerRef, tableRef }
  } = table;

  const { columnSizing, columnSizingInfo, columnVisibility } = getState();

  const columnSizesVars = useMemo(() => {
    const headers = getFlatHeaders();
    const colSizes: { [key: string]: number } = {};

    for (let i = 0; i < headers.length; i += 1) {
      const header = headers[i];
      const colSize = header.getSize();
      colSizes[`--header-${parseCssVarName(header.id)}-size`] = colSize;
      colSizes[`--col-${parseCssVarName(header.column.id)}-size`] = colSize;
    }

    return colSizes;
  }, [columns, columnSizing, columnSizingInfo, columnVisibility]);

  const columnVirtualizer = useDataTableColumnVirtualizer(table);

  return (
    <Table
      dense={dense}
      grid={grid}
      striped={striped}
      tableContainerRef={tableContainerRef}
      tableRef={tableRef}
      layoutMode={layoutMode}
      stickyHeader={enableStickyHeader}
      style={{ ...columnSizesVars }}
    >
      <DataTableHead table={table} columnVirtualizer={columnVirtualizer} />
      {columnSizingInfo.isResizingColumn ?
        (<MemoDataTableBody table={table} columnVirtualizer={columnVirtualizer} />) :
        (<DataTableBody table={table} columnVirtualizer={columnVirtualizer}/>)
      }
    </Table>
  );
};

