import { useMarkdown } from '@shared/markdown';
import { createColumnHelper } from '@tanstack/react-table';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { HiOutlineEnvelope, HiOutlineEnvelopeOpen, HiOutlineTrash } from 'react-icons/hi2';

import { Timestamp } from '@/components/date-time';
import { Button } from '@/components-new/button';
import { Checkbox, CheckboxGroup } from '@/components-new/checkbox';
import { DataPaginator } from '@/components-new/data-paginator';
import { DataTable, useDataTable } from '@/components-new/data-table';
import { RichTextPreview } from '@/components-new/rich-text-editor';
import { Notification } from '@/features/notifications/types/notification';

type NotificationsTableProps = {
  /**
   * The notifications to display in this table.
   */
  notifications: Notification[];

  /**
   * The notifications that are currently selected (checked).
   */
  selectedNotifications: Notification[];

  /**
   * Called when the user selects the checkbox next to one or more notifications.
   *
   * @param notifications The selected notifications.
   */
  setSelectedNotifications: (notifications: Notification[]) => void;

  /**
   * Called when a user chooses to view a notification.
   *
   * @param notification The notification the user wants to view.
   */
  onViewNotification: (notification: Notification) => void;

  /**
   * Called when a user chooses to delete all selectedNotifications.
   */
  onDelete: () => void;

  /**
   * Called when a user chooses to mark all selectedNotifications as read.
   */
  onMarkAsRead: () => void;

  /**
   * Called when a user chooses to mark all selectedNotifications as unread.
   */
  onMarkAsUnread: () => void;
};

const columnHelper = createColumnHelper<Notification>();

export const NotificationsTable = ({
  notifications,
  selectedNotifications,
  setSelectedNotifications,
  onViewNotification,
  onDelete,
  onMarkAsRead,
  onMarkAsUnread
}: NotificationsTableProps) => {
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const { toHtml } = useMarkdown();

  useEffect(() => {
    const isIndeterminate = selectedNotifications.length > 0 && selectedNotifications.length < notifications.length;
    setChecked(selectedNotifications.length === notifications.length);
    setIndeterminate(isIndeterminate);
  }, [notifications.length, selectedNotifications]);

  const toggleAll = () => {
    setSelectedNotifications(checked || indeterminate ? [] : notifications);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  };

  const readStateAction = selectedNotifications.some(notification => notification.read) ? 'unread' : 'read';

  const columns= [
    columnHelper.display({
      id: 'select',
      header: () => (
        <div className="relative px-7 sm:w-12 sm:px-6">
          {notifications && notifications.length > 0 && (
            <CheckboxGroup>
              <Checkbox
                color="secondary"
                checked={checked}
                indeterminate={indeterminate}
                onChange={toggleAll}
              />
            </CheckboxGroup>
          )}
        </div>
      ),
      meta: {
        className: 'max-w-12'
      },
      cell: ({ row }) => (
        <div className="relative px-7 sm:w-12 sm:px-6">
          {selectedNotifications.includes(row.original) && (
            <div className="absolute inset-y-0 left-0 w-0.5 bg-primary-950"/>
          )}
          <CheckboxGroup>
            <Checkbox
              color="secondary"
              checked={selectedNotifications.includes(row.original)}
              value={row.original.id.toString()}
              onChange={(checked) => {
                setSelectedNotifications(
                  checked
                    ? [...selectedNotifications, row.original]
                    : selectedNotifications.filter((it) => it !== row.original)
                );
              }}
            />
          </CheckboxGroup>
        </div>
      )
    }),
    columnHelper.display( {
      id: 'message',
      header: () => (
        <div>
          <div className="flex h-12 items-center space-x-3 bg-white sm:left-16">
            {selectedNotifications.length > 0 && (
              <>
                {readStateAction == 'unread' && (
                  <Button outline title="Mark as unread" onClick={onMarkAsUnread}>
                    <HiOutlineEnvelope className="size-5"/>
                  </Button>
                )}
                {readStateAction == 'read' && (
                  <Button outline title="Mark as read" onClick={onMarkAsRead}>
                    <HiOutlineEnvelopeOpen className="size-5"/>
                  </Button>
                )}
                <Button outline title="Delete" onClick={onDelete}>
                  <HiOutlineTrash className="size-5"/>
                </Button>
              </>
            )}
            {selectedNotifications.length == 0 && (
              <>Notification</>
            )}
          </div>
        </div>
      ),
      cell: ({ row }) => (
        <div
          className={clsx(
            'cursor-pointer whitespace-nowrap pr-3 text-sm font-medium',
            selectedNotifications.includes(row.original) ? 'text-primary-950' : 'text-gray-900',
          )}
          onClick={() => onViewNotification(row.original)}
        >
          <p className={clsx(row.original.read ? 'font-normal' : 'font-bold')}>
            {row.original.title}
          </p>
          <RichTextPreview content={toHtml(row.original.summary)} />
        </div>
      )
    }),
    columnHelper.accessor('sentAt', {
      header: 'Sent At',
      cell: info => <Timestamp wordBreak="break-word" color="text-strong" value={info.getValue()} />
    })];

  const table = useDataTable({
    data: notifications ?? [],
    columns,
    enablePagination: true,
  });

  return (
    <>
    <DataTable table={table}/>
    <DataPaginator className="pt-2" table={table} />
    </>
  );
};
