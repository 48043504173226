import { Cell, flexRender, Row } from '@tanstack/react-table';
import { VirtualItem } from '@tanstack/react-virtual';
import clsx from 'clsx';
import React, { CSSProperties, memo } from 'react';

import { DataTableInstance } from '@/components-new/data-table/use-data-table';
import { DataTableColumnVirtualizer } from '@/components-new/data-table/use-data-table-column-virtualizer';
import {
  DataTableRowVirtulizer,
  useDataTableRowVirtualizer
} from '@/components-new/data-table/use-data-table-row-virulizer';
import { useDataTableRows } from '@/components-new/data-table/use-data-table-rows';
import { TableBody, TableCell, TableRow } from '@/components-new/table';
import { parseCssVarName } from '@/utils/parsing';


/**
 * Renders the body of a data table,
 * supports virtualized row and column rendering for improved performance with large datasets.
 */
export const DataTableBody = <TData,>({
  table,
  columnVirtualizer
}: { table: DataTableInstance<TData>, columnVirtualizer?: DataTableColumnVirtualizer }) => {
  const {
    options: { layoutMode },
    refs: { tableContainerRef }
  } = table;

  const rows = useDataTableRows(table);

  const rowVirtualizer = useDataTableRowVirtualizer(table, rows);

  const { virtualRows } = rowVirtualizer ?? {};

  return (
    <TableBody
      className={clsx(
        layoutMode!.startsWith('grid') && 'grid',
      )}
      style={{
        height: rowVirtualizer && rows.length ? `${rowVirtualizer.getTotalSize()}px` : undefined,
        display: !rows.length ? 'table-row-group' : undefined,
      }}
    >
      {!rows.length ? (
        <TableRow
          className={clsx(
            layoutMode!.startsWith('grid') && 'grid'
          )}
        >
          <TableCell
            colSpan={table.getFlatHeaders().length}
            className={clsx(
              layoutMode!.startsWith('grid') && 'grid',
              'w-full text-center'
            )}
            style={{
              maxWidth: `min(100vw, ${tableContainerRef?.current?.clientWidth}px)`
            }}
          >
            No results found
          </TableCell>
        </TableRow>
      ): (
        <>
          {(virtualRows ?? rows).map((rowOrVirtualRow, renderedRowIndex) => {
            if (rowVirtualizer) {
              renderedRowIndex = rowOrVirtualRow.index;
            }

            const row = rowVirtualizer
              ? rows[renderedRowIndex]
              : (rowOrVirtualRow as Row<TData>);

            const virtualRow = rowVirtualizer
              ? (rowOrVirtualRow as VirtualItem)
              : undefined;

            return <DataTableBodyRow
              table={table}
              row={row}
              key={row.id}
              columnVirtualizer={columnVirtualizer}
              virtualRow={virtualRow}
              rowVirtualizer={rowVirtualizer}
            />;
          })}
        </>
      )}
    </TableBody>
  );
};

/**
 * Memoized version of the DataTableBody component.
 *
 * This constant uses the React `memo` function to optimize rendering by memoizing
 * the DataTableBody component.
 * It performs a custom comparison to check if the
 * `data` property in the `options` of the `table` object in the previous props
 * is the same as that in the next props.
 * If they are equal, the component does not
 * re-render, enhancing performance by preventing unnecessary renders.
 */
export const MemoDataTableBody = memo(
  DataTableBody,
  (prev, next) => {
    return prev.table.options.data === next.table.options.data;
  },
) as typeof DataTableBody;

type DataTableBodyRowProps<TData> = {
  table: DataTableInstance<TData>,
  row: Row<TData>,
  columnVirtualizer?: DataTableColumnVirtualizer,
  virtualRow?: VirtualItem
  rowVirtualizer?: DataTableRowVirtulizer,
}


/**
 * Renders a single row in the body of a data table.
 * It supports handling of virtualized rows and columns for optimized scrolling performance.
 */
const DataTableBodyRow = <TData,>({
  table,
  row,
  columnVirtualizer,
  virtualRow,
  rowVirtualizer
}: DataTableBodyRowProps<TData>) => {
  const {
    options: {
      layoutMode,
      tableBodyRowProps,
      showAggregateGroupRow
    },
  } = table;

  const { virtualColumns, virtualPaddingLeft, virtualPaddingRight } = columnVirtualizer ?? {};

  const visibleCells = row.getVisibleCells();

  const rowProps = tableBodyRowProps?.({ table, row }) ?? {};

  if (!showAggregateGroupRow && row.getIsGrouped()) return null;

  const positionStyle: CSSProperties = rowVirtualizer ? {
    display: 'flex',
    position: 'absolute',
    transform: `translateY(${virtualRow!.start}px)`, //this should always be a `style` as it changes on scroll
    width: '100%',
  } : {};

  const rowPropsStyle: CSSProperties = rowProps.style ?? {};

  return (
    <TableRow
      className={clsx(rowProps?.className)}
      data-index={virtualRow?.index ?? undefined} //needed for dynamic row height measurement
      ref={(node) => rowVirtualizer?.measureElement(node)} //measure dynamic row height
      style={{
        ...positionStyle,
        ...rowPropsStyle,
      }}
    >
      {virtualPaddingLeft ? (
        //fake empty column to the left for virtualization scroll padding
        <td style={{ display: 'flex', width: virtualPaddingLeft }} />
      ) : null}
      {(virtualColumns ?? visibleCells).map(
        (cellOrVirtualCell, renderedColumnIndex) => {
          let cell = cellOrVirtualCell as Cell<TData, unknown>;
          if (columnVirtualizer) {
            renderedColumnIndex = (cellOrVirtualCell as VirtualItem)
              .index;
            cell = visibleCells[renderedColumnIndex];
          }

          return <DataTableBodyCell key={cell.column.id} cell={cell} table={table} />;
        })
      }
      {virtualPaddingRight ? (
        //fake empty column to the right for virtualization scroll padding
        <td style={{ display: 'flex', width: virtualPaddingRight }} />
      ) : null}
      {layoutMode === 'grid-fixed' && (
        <TableCell className="flex flex-1" style={{ padding: 0 }}/>
      )}
    </TableRow>
  );
};


/**
 * Renders a table body cell within a data table.
 *
 * This component manages the styling and layout of the cell based on various properties from the cell and table instance.
 * It handles features such as column pinning, dynamic sizing, and responsive alignment.
 */
export const DataTableBodyCell = <TData,>({
  table,
  cell
}: { cell: Cell<TData, unknown>, table: DataTableInstance<TData> }) => {
  const { column } = cell;
  const { columnDef } = column;
  const {
    getState,
    options: {
      layoutMode,
      enableColumnPinning,
      columnResizeDirection
    }
  } = table;

  const { columnSizingInfo } = getState();

  const isColumnPinned = enableColumnPinning && column.getIsPinned();

  const widthStyles: CSSProperties = {
    minWidth: `max(calc(var(--header-${parseCssVarName(
      column?.id,
    )}-size) * 1px), ${columnDef.minSize ?? 30}px)`,
    width: `calc(var(--header-${parseCssVarName(column.id)}-size) * 1px)`,
  };

  if (layoutMode === 'grid') {
    const grow = columnDef.meta?.grow ?? true;
    widthStyles.flex = `${
      [0, false].includes(grow)
        ? 0
        : `var(--header-${parseCssVarName(column.id)}-size)`
    } 0 auto`;
  } else if (layoutMode === 'grid-fixed') {
    widthStyles.flex = `${+(columnDef.meta?.grow || 0)} 0 auto`;
  }

  const pinnedStyles = {
    left: isColumnPinned === 'left' ? `${column.getStart(isColumnPinned)}px` : undefined,
    right: isColumnPinned === 'right' ? `${column.getAfter(isColumnPinned)}px`: undefined,
  };

  return (
    <TableCell
      data-column-resizing={(columnSizingInfo.isResizingColumn === column.id && columnResizeDirection) || undefined}
      data-column-pinned={isColumnPinned || undefined}
      data-column-last-pinned-left={isColumnPinned === 'left' && column.getIsLastColumn(isColumnPinned) || undefined}
      data-column-first-pinned-left={isColumnPinned === 'left' && column.getIsFirstColumn(isColumnPinned) || undefined}
      className={clsx(
        'data-[column-pinned]:sticky data-[column-pinned]:z-[1]',
        'data-[column-last-pinned-left]:border-r-2',
        'data-[column-first-pinned-left]:border-l-0 data-[column-first-pinned-left]:!pl-2',
        'data-[column-resizing=ltr]:border-r-secondary-600 data-[column-resizing=rtl]:border-l-secondary-600',
        'data-[column-resizing=ltr]:border-r-2 data-[column-resizing=rtl]:border-l-2'
      )}
      style={{
        ...pinnedStyles,
        ...widthStyles
      }}
      >
      <div className="overflow-hidden text-ellipsis whitespace-normal">
      {
        flexRender(
          cell.column.columnDef.cell,
          cell.getContext()
        )
      }
      </div>
    </TableCell>
  );
};
