import clsx from 'clsx';
import React from 'react';

const styles = {
  base: 'overflow-hidden sm:rounded-lg',
  basic: 'bg-white shadow',
  well: 'bg-gray-50',
  outlined: 'border border-zinc-200',
};

type CardProps = {
  well?: boolean,
  outlined?: boolean,
  dense?: boolean,
  bleed?: boolean,
  className?: string,
} & React.ComponentPropsWithoutRef<'div'>;

export const Card = ({
  children,
  className,
  well = false,
  outlined = false,
  bleed = false,
  dense = false,
  ...props
}: CardProps) => {
  const classes = clsx(
    styles.base,
    well ? styles.well : styles.basic,
    outlined && [styles.outlined, styles.basic],
    className
  );

  return (
    <div {...props} className={classes}>
      <div className={clsx(
        dense && !bleed && 'px-3 py-2 sm:p-4',
        !dense && !bleed && 'px-4 py-5 sm:p-6',
      )}>
        {children}
      </div>
    </div>
  );
};
