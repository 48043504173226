import {
  ColumnOrderState, ColumnPinningState,
  ColumnSizingInfoState,
  GroupingState, PaginationState,
  Table as TableInstance,
  useReactTable
} from '@tanstack/react-table';
import React, { useMemo, useRef, useState } from 'react';

import {
  DataTableOptions,
  ExtendedTableOptions,
  useDataTableOptions
} from '@/components-new/data-table/use-data-table-options';

/**
 * Represents the base options for configuring a table instance in a type-safe manner.
 * This type is derived from the `options` property of the `TableInstance` for the
 * provided generic type `TData`.
 */
type BaseTableOptions<TData> = TableInstance<TData>['options'];

/**
 * Represents an instance of a data table with additional references and options.
 *
 * This type is used to define the properties and methods available in a table
 * instance, combining base table options, extended table options, and standard
 * table instance properties. It also includes references to table-related DOM
 * elements for dynamic interactions.
 */
export type DataTableInstance<TData> = {

  /**
   * References to DOM elements used within the component.
   */
  refs: {
    /**
     * A reference to the HTMLDivElement that serves as the container for the table.
     */
    tableContainerRef?: React.RefObject<HTMLDivElement> | null
    /**
     * A reference to the HTMLTableElement representing the table.
     */
    tableRef?: React.RefObject<HTMLTableElement> | null
  }

  /**
   * Represents the configuration options for a table. Combines the base table options and extended table options, allowing customization and additional functionality.
   */
  options: BaseTableOptions<TData> & ExtendedTableOptions<TData>
} & TableInstance<TData>;


/**
 * Creates and manages a data table instance with advanced features such as column ordering,
 * column resizing, grouping, pagination, column pinning, and global filtering.
 * It utilizes options provided during initialization and maintains internal state
 * to handle various table functionalities dynamically.
 */
export const useDataTable = <TData>(options: DataTableOptions<TData>): DataTableInstance<TData> => {
  const definedOptions = useDataTableOptions(options);
  const tableContainerRef = useRef<HTMLDivElement | null>(null);
  const tableRef = useRef<HTMLTableElement | null>(null);


  // defines the initial state and only creates it once
  const initialState = useMemo(
    () => definedOptions.initialState ?? {},
    []
  );

  definedOptions.initialState = initialState;

  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(initialState.columnOrder ?? []);
  const [columnSizingInfo, setColumnSizingInfo] = useState<ColumnSizingInfoState>(initialState.columnSizingInfo ?? {} as ColumnSizingInfoState);
  const [grouping, setGrouping] = useState<GroupingState>(initialState.grouping ?? []);
  const [pagination, onPaginationChange] = useState<PaginationState>(initialState.pagination ? {
    pageIndex: initialState.pagination.pageIndex ?? 0,
    pageSize: initialState.pagination.pageSize ?? 10,
  } : { pageIndex: 0, pageSize: 10 }
  );
  const [columnPinning, setColumnPinning] = useState<ColumnPinningState>(initialState.columnPinning ?? { left: [], right: [] });
  const [globalFilter, setGlobalFilter] = useState<string>('');

  definedOptions.state = {
    columnOrder,
    columnSizingInfo,
    grouping,
    pagination,
    globalFilter,
    columnPinning,
    ...definedOptions.state,
  };

  const table = useReactTable({
    onColumnOrderChange: setColumnOrder,
    onColumnSizingInfoChange: setColumnSizingInfo,
    onGroupingChange: setGrouping,
    onPaginationChange: onPaginationChange,
    onGlobalFilterChange: setGlobalFilter,
    onColumnPinningChange: setColumnPinning,
    ...definedOptions
  }) as DataTableInstance<TData>;

  table.refs = {
    tableContainerRef,
    tableRef
  };

  return table;
};
