import { isEmpty, isNil, trim } from 'lodash';

export const safeJsonParse = <T>(str?: string) => {
  try {
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    return JSON.parse(str) as T;
  } catch {
    return undefined;
  }
};

export const coerceStringNull = (value: string | null | undefined) => {
  if (isNil(value)) return null;

  if (isEmpty(trim(value))) return null;

  return value;
};

export const parseCssVarName = (name: string) => name.replace(/[^a-zA-Z0-9]/g, '_');
