import { isAnyArtiaUser } from '@shared/auth/policies';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { HiChevronDown } from 'react-icons/hi2';
import { LiaFileMedicalSolid } from 'react-icons/lia';
import { useSearchParams } from 'react-router-dom';

import { Breadcrumbs } from '@/components-new/breadcrumbs';
import { Button } from '@/components-new/button';
import { Card } from '@/components-new/card';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from '@/components-new/dropdown';
import { EmptyState, EmptyStateBody, EmptyStateHeading } from '@/components-new/empty-state';
import { Loader } from '@/components-new/loader';
import { Overlay } from '@/components-new/overlay';
import { Page } from '@/components-new/page';
import { PageTitleRow } from '@/components-new/page-title-row';
import { useStateReports } from '@/features/state-reports/new/api';
import StateReportTable from '@/features/state-reports/new/components/state-report-table';
import { StateReport, StateReportType } from '@/features/state-reports/new/types/state-report';
import { useCurrentUser } from '@/hooks/use-current-user';
import { useNavigate } from '@/router';

const StateReportsPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const searchReport = searchParams.get('report');

  const { user: currentUser } = useCurrentUser();
  const canAccessPremiumStateReports = isAnyArtiaUser(currentUser) || currentUser.premiumFeatures.includes('state_reports_premium');
  const { data: stateReportsData, isLoading: isLoadingStateReports } = useStateReports();

  const reportList = useMemo(() => {
    if (!stateReportsData) return [];

    return stateReportsData;
  }, [stateReportsData]);

  // currently selected state report
  const [selectedReport, setSelectedReport] = useState<StateReport>();

  const canAccessReport = canAccessPremiumStateReports || selectedReport?.type !== StateReportType.PREMIUM;

  // reference to the select report dropdown
  const dropdownRef = useRef<HTMLButtonElement>(null);

  const handleReportSelection = (report: StateReport) => {
    searchParams.set('report', report.key);
    navigate(
      {
        pathname: '/state-reports',
        search: searchParams.toString()
      }
    );

    setSelectedReport(report);
  };

  useEffect(() => {
    const foundReport = reportList.find(report => report.key === searchReport);

    if (foundReport) {
      setSelectedReport(foundReport);
    }
  }, [reportList, searchReport]);

  const showEmptyState = !isLoadingStateReports && reportList.length > 0 && !selectedReport;
  const showReportTable = !isLoadingStateReports && selectedReport;

  return (
    <Page
      title="State Reports"
      header={({ title }) => (
        <>
          <Breadcrumbs breadcrumbs={[{ label: 'State Reports', url: '/state-reports' }]} />
          <PageTitleRow title={title}>
            <Dropdown>
              <DropdownButton outline ref={dropdownRef} disabled={isLoadingStateReports}>
                Choose a report
                <HiChevronDown />
              </DropdownButton>
              <DropdownMenu>
                <div className="grid w-80 grid-cols-2 gap-4 p-4 sm:w-[450px] sm:grid-cols-2">
                  <div>
                    <div className="px-2 py-1.5 text-sm font-semibold">Standard Reports</div>
                    <div role="separator" aria-orientation="horizontal" className="-mx-1 my-1 h-px bg-zinc-400"></div>
                    <div role="group">
                      {reportList.filter(stateReport => stateReport.type === StateReportType.STANDARD).map(stateReport => (
                          <DropdownItem
                            className="w-full"
                            key={stateReport.key}
                            onClick={() => handleReportSelection(stateReport)}>
                            {stateReport.name}
                          </DropdownItem>
                        )
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="px-2 py-1.5 text-sm font-semibold">Premium Reports</div>
                    <div role="separator" aria-orientation="horizontal" className="-mx-1 my-1 h-px bg-zinc-400"></div>
                    <div role="group">
                      {reportList.filter(stateReport => stateReport.type === StateReportType.PREMIUM).map(stateReport => (
                          <DropdownItem
                            className="w-full"
                            key={stateReport.key}
                            onClick={() => handleReportSelection(stateReport)}>
                            {stateReport.name}
                          </DropdownItem>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </DropdownMenu>
            </Dropdown>
          </PageTitleRow>
        </>
      )}
    >
      {showReportTable &&
        <StateReportTable
          stateReport={selectedReport}
          canAccessReport={canAccessReport}
        />
      }
      {showEmptyState &&
        <Card well>
          <EmptyState>
              <div>
                <LiaFileMedicalSolid className="size-12 text-gray-500"/>
              </div>
              <EmptyStateHeading>No report selected</EmptyStateHeading>
              <EmptyStateBody>
                <p>Get started by choosing a report from the dropdown.</p>
                <Button className="mt-6" onClick={() => dropdownRef.current?.click()}>Choose Report</Button>
              </EmptyStateBody>
          </EmptyState>
        </Card>
      }
      {isLoadingStateReports &&
        <div className="relative min-h-20">
          <Overlay>
            <Loader message="Loading reports..."/>
          </Overlay>
        </div>
      }
    </Page>
  );
};

export default StateReportsPage;
