import { flexRender, Header, HeaderGroup } from '@tanstack/react-table';
import { VirtualItem } from '@tanstack/react-virtual';
import clsx from 'clsx';
import React, { CSSProperties, useMemo } from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi2';

import { DataTableInstance } from '@/components-new/data-table/use-data-table';
import { DataTableColumnVirtualizer } from '@/components-new/data-table/use-data-table-column-virtualizer';
import { TableHead, TableHeader, TableRow } from '@/components-new/table';
import { parseCssVarName } from '@/utils/parsing';

/**
 * Renders the header section of a data table.
 * Supports features such as sticky headers and virtualized columns.
 */
export const DataTableHead = <TData,>({
  table,
  columnVirtualizer
}: { table: DataTableInstance<TData>, columnVirtualizer?: DataTableColumnVirtualizer }) => {
  const {
    getHeaderGroups,
    options: { enableStickyHeader, layoutMode }
  } = table;
  return (
    <TableHead
      className={clsx((enableStickyHeader && layoutMode?.startsWith('grid')) && 'sticky')}
    >
      {getHeaderGroups().map((headerGroup) => (
        <DataTableHeadRow
          key={headerGroup.id}
          table={table}
          headerGroup={headerGroup}
          columnVirtualizer={columnVirtualizer}
        />
      ))}
    </TableHead>
  );
};

/**
 * Represents the header row component of a data table.
 */
const DataTableHeadRow = <TData,>({
  table,
  headerGroup,
  columnVirtualizer
}: { table: DataTableInstance<TData>, headerGroup: HeaderGroup<TData>, columnVirtualizer?: DataTableColumnVirtualizer }) => {
  const {
    options: {
      enableStickyHeader,
      layoutMode
    },
  } = table;

  const { virtualColumns, virtualPaddingLeft, virtualPaddingRight } =
  columnVirtualizer ?? {};

  return (
    <TableRow className={clsx(
      layoutMode!.startsWith('grid') && 'flex',
      layoutMode!.startsWith('semantic') && enableStickyHeader ?
        'sticky' :
        'relative',
      'top-0 bg-white shadow-sm'
    )}>
      {virtualPaddingLeft ? (
        //fake empty column to the left for virtualization scroll padding
        <th style={{ display: 'flex', width: virtualPaddingLeft }} />
      ) : null}
      {(virtualColumns ?? headerGroup.headers).map((headerOrVirtualHeader: unknown, renderedHeaderIndex) => {
        let header = headerOrVirtualHeader as Header<TData, unknown>;
        if (columnVirtualizer) {
          renderedHeaderIndex = (headerOrVirtualHeader as VirtualItem).index;
        }
        header = headerGroup.headers[renderedHeaderIndex];
        return <DataTableHeaderCell header={header} table={table} key={header.id}/>;
      })}
      {layoutMode === 'grid-fixed' && (
        <TableHeader className="flex flex-1" style={{ padding: 0 }}/>
      )}
      {virtualPaddingRight ? (
        //fake empty column to the right for virtualization scroll padding
        <th style={{ display: 'flex', width: virtualPaddingRight }} />
      ) : null}
    </TableRow>
  );
};

/**
 * Renders the header cell of a data table.
 * It utilizes dynamic styling to adjust widths, pinned positions, and other visual aspects
 * based on column definitions and the data table state.
 */
const DataTableHeaderCell = <TData,>({
  header,
  table
}: { header: Header<TData, unknown>, table: DataTableInstance<TData> }) => {
  const { column } = header;
  const { columnDef } = column;
  const {
    getState,
    options: {
      layoutMode,
      enableColumnPinning,
      columnResizeDirection,
    }
  } = table;

  const { columnSizingInfo } = getState();
  const isColumnPinned = enableColumnPinning && column.getIsPinned();

  const isSortable = column.getCanSort();
  const sortHandler = column.getToggleSortingHandler();
  const nextSortDirection = column.getNextSortingOrder();

  const title = useMemo(() => {
    if (!isSortable) return undefined;

    if (nextSortDirection === 'asc') return 'Sort ascending';

    if (nextSortDirection === 'desc') return 'Sort descending';

    return 'Clear sort';
  },[isSortable, nextSortDirection]);

  const widthStyles: CSSProperties = {
    minWidth: `max(calc(var(--header-${parseCssVarName(
      header?.id,
    )}-size) * 1px), ${columnDef.minSize ?? 30}px)`,
    width: `calc(var(--header-${parseCssVarName(header.id)}-size) * 1px)`,
  };

  if (layoutMode === 'grid') {
    const grow = columnDef.meta?.grow ?? true;
    widthStyles.flex = `${
      [0, false].includes(grow)
        ? 0
        : `var(--header-${parseCssVarName(header.id)}-size)`
    } 0 auto`;
  } else if (layoutMode === 'grid-fixed') {
    widthStyles.flex = `${+(columnDef.meta?.grow || 0)} 0 auto`;
  }

  const pinnedStyles = {
    left: isColumnPinned === 'left' ? `${column.getStart(isColumnPinned)}px` : undefined,
    right: isColumnPinned === 'right' ? `${column.getAfter(isColumnPinned)}px`: undefined,
  };

  return (
    <TableHeader
      data-column-resizing={(columnSizingInfo.isResizingColumn === column.id && columnResizeDirection) || undefined}
      data-column-pinned={isColumnPinned || undefined}
      data-column-last-pinned-left={isColumnPinned === 'left' && column.getIsLastColumn(isColumnPinned) || undefined}
      data-column-first-pinned-left={isColumnPinned === 'left' && column.getIsFirstColumn(isColumnPinned) || undefined}
      colSpan={header.colSpan}
      className={clsx(
        'group bg-inherit',
        layoutMode!.startsWith('grid') && 'flex-col',
        'data-[column-pinned]:sticky data-[column-pinned]:z-[1]',
        'data-[column-last-pinned-left]:border-r-2',
        'data-[column-first-pinned-left]:border-l-0 data-[column-first-pinned-left]:!pl-2',
        'data-[column-resizing=ltr]:border-r-secondary-600 data-[column-resizing=rtl]:border-l-secondary-600',
        'data-[column-resizing=ltr]:border-r-2 data-[column-resizing=rtl]:border-l-2'
      )}
      style={{
        ...widthStyles,
        ...pinnedStyles
      }}
    >
      {header.isPlaceholder ?
        null :
        (
          <div
            className={clsx(
              'relative flex w-full items-center',
              column.getCanResize() ? 'justify-between' : 'justify-start'
            )}
          >
            <div
              className={clsx(
                'flex flex-row items-center overflow-hidden',
                isSortable && 'group cursor-pointer select-none'
              )}
              onClick={sortHandler}
              title={title}
            >
              <div
                className={clsx('truncate')}
                style={{
                  minWidth: `${Math.min(columnDef.header?.length ?? 0, 4)}ch`
                }}
              >
                {flexRender(
                  columnDef.header,
                  header.getContext()
                )}
              </div>
              {isSortable && <DataTableColumnSortButton header={header} />}
            </div>
            {column.getCanResize() && <DataTableResizeHandle header={header} table={table} />}
          </div>
        )
      }
    </TableHeader>
  );
};

/**
 * Handler for resizing of table columns within a data table.
 * It allows interaction via mouse or touch events to resize columns and provides a visual indicator for resizing.
 */
const DataTableResizeHandle = <TData,>({
  header,
  table
}: { header: Header<TData, unknown>; table: DataTableInstance<TData>; }) => {
  const {
    getState,
    setColumnSizingInfo,
    options: {
      columnResizeMode
    }
  } = table;
  const { column } = header;

  const handler = header.getResizeHandler();

  return (
    <div
      onDoubleClick={() => {
        setColumnSizingInfo(old => ({
          ...old,
          isResizingColumn: false
        }));
        column.resetSize();
      }}
      onMouseDown={handler}
      onTouchStart={handler}
      className="absolute right-0 -mr-4 cursor-col-resize touch-none select-none px-4 group-[:last-child]:-mr-1 group-[:not(:last-child)]:-mr-4"
      style={{
        // onChange is preferred, but in the case it's not set at least move the handle correctly
        transform: column.getIsResizing() && columnResizeMode === 'onEnd'
          ? `translateX(${getState().columnSizingInfo.deltaOffset ?? 0}px)`
          : undefined
      }}
    >
      <div
        className="z-[4] h-6 translate-x-1 touch-none rounded-sm border-2 border-zinc-200"
      />
    </div>
  );
};

const SortAscIcon = () => <HiChevronUp className="size-4"/>;
const SortDescIcon = () => <HiChevronDown className="size-4"/>;

/**
 * Represents a sort button for a data table column.
 *
 * This component renders a button responsible for handling column sorting in a data table.
 * It visually indicates the current sort direction (ascending, descending, or none)
 * and the next sort direction if sorting is not active on the column.
 */
const DataTableColumnSortButton = <TData,>({
  header
}: { header: Header<TData, unknown> }) => {
  const { column } = header;
  const sortDirection = column.getIsSorted();
  const nextSortDirection = column.getNextSortingOrder();

  return (
    <span
      className={clsx(
        'relative ml-2 inline-flex shrink-0 rounded p-0.5',
        sortDirection && 'bg-gray-100 text-gray-900 group-hover:bg-gray-200',
        !sortDirection && 'invisible text-gray-400 group-hover:visible group-focus:visible'
      )}
    >
      {sortDirection && (
        sortDirection === 'asc'
          ? <SortAscIcon/>
          : <SortDescIcon/>
      )}
      {!sortDirection && (
        nextSortDirection === 'desc'
          ? <SortAscIcon/>
          : <SortDescIcon/>
      )}
    </span>
  );
};
