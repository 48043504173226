import { defaultRangeExtractor, useVirtualizer, VirtualItem, Virtualizer } from '@tanstack/react-virtual';
import { useMemo } from 'react';

import { DataTableInstance } from '@/components-new/data-table/use-data-table';

/**
 * Represents a virtualized set of columns for a data table, providing the ability to manage and render
 * only visible or required columns efficiently. This is especially useful for large datasets to improve
 * performance by minimizing DOM.
 */
export type DataTableColumnVirtualizer = {

  /**
   * Represents an array of virtual columns used for dynamically managing
   * or displaying items in a virtualized context (e.g., virtual scrolling,
   * rendering optimization).
   */
  virtualColumns: VirtualItem[];

  /**
   * Represents the amount of virtual padding applied to the left side of an element or container.
   * This padding represents the estimated physical space the columns would consume.
   */
  virtualPaddingLeft?: number;

  /**
   * Represents the amount of virtual padding applied to the right side of an element or container.
   * This padding represents the estimated physical space the columns would consume.
   */
  virtualPaddingRight?: number;
} & Virtualizer<HTMLDivElement, HTMLTableCellElement>;


/**
 * Enables column virtualization in a data table instance. It provides optimized rendering
 * for visible columns while accounting for pinned columns when virtualization is enabled. If column virtualization
 * is not enabled, the function will return `undefined`.
 *
 * Limitations:
 * - Column virtualization is non-toggleable and relies on the `enableColumnVirtualization` option.
 */
export const useDataTableColumnVirtualizer = <TData>(table: DataTableInstance<TData>) => {
  const {
    getLeftLeafColumns,
    getRightLeafColumns,
    getState,
    getVisibleLeafColumns,
    options: {
      enableColumnVirtualization,
      columnVirtualizerOptions,
      columnVirtualizerInstanceRef,
      enableColumnPinning
    },
    refs: { tableContainerRef }
  } = table;

  const { columnPinning } = getState();

  if (!enableColumnVirtualization) return undefined;

  const visibleColumns = getVisibleLeafColumns();

  // column virtualization is not toggleable
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [leftPinnedIndexes, rightPinnedIndexes] = useMemo(
    () => enableColumnPinning
      ? [
        getLeftLeafColumns().map((c) => c.getPinnedIndex()),
        getRightLeafColumns()
          .map(
            (column) => visibleColumns.length - column.getPinnedIndex() - 1,
          )
          .sort((a, b) => a - b),
      ]
    : [[], []], [visibleColumns.length, columnPinning, enableColumnPinning]);

  const pinnedLeftCount = leftPinnedIndexes.length;
  const pinnedRightCount = rightPinnedIndexes.length;

  // column virtualization is not toggleable
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const columnVirtualizer = useVirtualizer({
    count: visibleColumns.length,
    estimateSize: (index) => visibleColumns[index].getSize(),
    getScrollElement: () => tableContainerRef?.current ?? null,
    horizontal: true,
    overscan: 3,
    rangeExtractor: (range) => {
      const newIndexes = defaultRangeExtractor(range);

      if (!pinnedLeftCount && !pinnedRightCount) {
        return newIndexes;
      }

      return [
        ...new Set([
          ...leftPinnedIndexes,
          ...newIndexes,
          ...rightPinnedIndexes,
        ])
      ];
    },
    ...columnVirtualizerOptions,
  }) as DataTableColumnVirtualizer;

  const virtualColumns = columnVirtualizer.getVirtualItems();
  columnVirtualizer.virtualColumns = virtualColumns;
  const columnCount = virtualColumns.length;

  // account for the pinned columns and apply virtual padding accordingly
  if (columnCount) {
    const totalSize = columnVirtualizer.getTotalSize();
    const leftNonPinnedStart = virtualColumns[pinnedLeftCount]?.start || 0;
    const leftNonPinnedEnd =
      virtualColumns[leftPinnedIndexes.length - 1]?.end || 0;


    const rightNonPinnedStart =
      virtualColumns[columnCount - pinnedRightCount]?.start || 0;
    const rightNonPinnedEnd =
      virtualColumns[columnCount - pinnedRightCount - 1]?.end || 0;

    columnVirtualizer.virtualPaddingLeft = leftNonPinnedStart - leftNonPinnedEnd;
    columnVirtualizer.virtualPaddingRight = totalSize - rightNonPinnedEnd - (pinnedRightCount ? totalSize - rightNonPinnedStart : 0);
  }

  if (columnVirtualizerInstanceRef?.current) {
    columnVirtualizerInstanceRef.current = columnVirtualizer;
  }

  return columnVirtualizer;
};
