import { Row } from '@tanstack/react-table';
import { useVirtualizer, VirtualItem, Virtualizer } from '@tanstack/react-virtual';

import { DataTableInstance } from '@/components-new/data-table/use-data-table';

/**
 * Represents a data table row virtualizer that combines virtual row handling
 * with Virtualizer functionality for efficient rendering of table rows.
 */
export type DataTableRowVirtulizer = {
  /**
   * Represents an array of virtual items used for efficiently rendering
   * or managing a large collection of data in a virtualized manner.
   */
  virtualRows: VirtualItem[]
} & Virtualizer<HTMLDivElement, HTMLTableRowElement>


/**
 * Enables virtualization of rows in a data table. This function optimizes rendering
 * performance by only rendering the rows currently visible in the viewport along with a few additional rows
 * for smoother scrolling.
 */
export const useDataTableRowVirtualizer = <TData>(
  table: DataTableInstance<TData>,
  rows?: Row<TData>[]
) => {
  const {
    getRowModel,
    options: {
      enableRowVirtualization,
      rowVirtualizerInstanceRef,
      rowVirtualizerOptions,
      dense,
    },
    refs: { tableContainerRef },
  } = table;

  if (!enableRowVirtualization) return undefined;

  const rowCount = rows?.length ?? getRowModel().rows.length;

  // estimate the row height to provide a more accurate scrollbar
  const estimatedRowHeight = dense ? 45 : 57;

  const rowVirtualizer = useVirtualizer({
    count: rowCount,
    estimateSize: () => estimatedRowHeight,
    getScrollElement: () => tableContainerRef?.current ?? null,
    measureElement:
      typeof window !== 'undefined' &&
      navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 4,
    ...rowVirtualizerOptions,
  }) as unknown as DataTableRowVirtulizer;

  rowVirtualizer.virtualRows = rowVirtualizer.getVirtualItems();

  if (rowVirtualizerInstanceRef) {
    rowVirtualizerInstanceRef.current = rowVirtualizer;
  }

  return rowVirtualizer;
};
