import React from 'react';

/**
 * Allows rendering a page title as a flex row of page title followed by
 * supporting icons or other content.
 */
export const PageTitleRow = ({ title, subtitle, children }: PageTitleRowProps) => {
  return (
    <div className="my-3 sm:flex sm:flex-row sm:items-center sm:justify-between">
      <div className="min-w-0 flex-1">
        <div className="font-serif text-2xl font-bold leading-7 text-primary-950 dark:text-white sm:truncate sm:text-4xl sm:tracking-tight">
          {title}
        </div>
        {subtitle && (
          <div className="text-sm font-medium text-gray-600">
            {subtitle}
          </div>
        )
        }
      </div>
      <div className="mt-4 flex shrink-0 items-center gap-x-4 sm:gap-x-6 md:ml-4 md:mt-0">
        {children}
      </div>
    </div>
  );
};

type PageTitleRowProps = {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  children?: React.ReactNode;
};
