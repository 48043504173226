import { DateTime } from 'luxon';
import React from 'react';

import Nullish from '@/components/nullish';
import { TextLink } from '@/components-new/text';
import { ColumnDefinition } from '@/features/state-reports/new/types/state-report';
import { formatNumber } from '@/utils/formatting';
import { isValidUrl } from '@/utils/urls';

type TableCellProps = {
  value: any,
  columnDefinition: ColumnDefinition,
}

export const StringCell = ({ value }: TableCellProps) => {
  return <Nullish value={value} />;
};

export const IntegerCell = ({  value  }: TableCellProps) => {
  return <>{formatNumber(value, 0)}</>;
};

export const BooleanCell = ({ value }: TableCellProps ) => {
  return <>{value.toLowerCase() == 'true' ? 'Yes' : 'No'}</>;
};

export const UrlCell = ({ value, columnDefinition }: TableCellProps) => {
  return <>{isValidUrl(value) ? <TextLink href={value} target="_blank">{columnDefinition.header}</TextLink> : <Nullish value={value} />}</>;
};

export const DataOnlyCell = ({ value }: TableCellProps ) => {
  return (<>{DateTime.fromFormat(value, 'yyyy-mm-dd').toFormat('DDD')}</>);
};
