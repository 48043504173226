'use client';

import clsx from 'clsx';
import React, { forwardRef } from 'react';
import { createContext, useContext, useState } from 'react';

import { createContextHook } from '@/utils/hooks';

import { Link } from './link';

const TableContext = createContext<{
  bleed: boolean;
  dense: boolean;
  grid: boolean;
  striped: boolean;
  stickyHeader: boolean;
  layoutMode?: TableProps['layoutMode'];
}>({
  bleed: false,
  dense: false,
  grid: false,
  striped: false,
  stickyHeader: false,
  layoutMode: 'semantic',
});

const useTableContext = createContextHook(TableContext);

export type TableProps = {
  bleed?: boolean;
  dense?: boolean;
  grid?: boolean;
  striped?: boolean;
  tableElementProps?: React.ComponentPropsWithRef<'table'>;
  tableContainerProps?: React.ComponentPropsWithRef<'div'>;
  stickyHeader?: boolean;
  layoutMode?: 'grid' | 'grid-fixed' | 'semantic',
  tableRef?: React.Ref<HTMLTableElement>;
  tableContainerRef?: React.Ref<HTMLDivElement>;
} & React.ComponentPropsWithoutRef<'div'>;

export const Table = (
  {
    bleed = false,
    dense = false,
    grid = false,
    striped = false,
    stickyHeader = false,
    layoutMode = 'semantic',
    tableElementProps = {},
    tableContainerProps = {},
    className,
    children,
    tableContainerRef,
    tableRef,
    ...props
  }: TableProps
) => {
  const {
    className: tableElementClassName,
    ...restTableElementProps
  } = tableElementProps;

  const {
    className: tableContainerClassName,
  }= tableContainerProps;

  return (
    <TableContext.Provider value={{ bleed, dense, grid, striped, stickyHeader, layoutMode } as React.ContextType<typeof TableContext>}>
      <div
        ref={tableContainerRef}
        {...props}
        className={clsx(
          stickyHeader && 'max-h-[clamp(350px,calc(100vh_-_180px),9999px)]',
          'relative w-full max-w-full overflow-auto',
          className,
          tableContainerClassName
        )
      }>
        <table
          ref={tableRef}
          className={clsx(
            tableElementClassName,
            layoutMode?.startsWith('grid') && 'grid',
            'relative -mx-[--gutter] w-full border-separate border-spacing-0 text-left text-sm/6 text-zinc-950',
            !bleed && 'px-[--gutter]'
          )}
          {...restTableElementProps}
        >
          {children}
        </table>
      </div>
    </TableContext.Provider>
  );
};

export function TableHead({ className, ...props }: React.ComponentPropsWithoutRef<'thead'>) {
  const { stickyHeader, layoutMode } = useTableContext();
  return <thead
    {...props}
    className={clsx(
      className,
      layoutMode?.startsWith('grid') && 'grid',
      layoutMode?.startsWith('grid') && stickyHeader && 'top-0',
      stickyHeader ? 'sticky z-[2]' : 'relative',
      'text-zinc-500'
    )}
  />;
}

export function TableBody({ className, ...props }: React.ComponentPropsWithoutRef<'tbody'>) {
  return <tbody {...props} className={clsx(className, 'relative')} />;
}

const TableRowContext = createContext<{ href?: string; target?: string; title?: string }>({
  href: undefined,
  target: undefined,
  title: undefined,
});

type TableRowProps = {
  href?: string;
  target?: string;
  title?: string
} & React.ComponentPropsWithoutRef<'tr'>;

export const TableRow = forwardRef<HTMLTableRowElement, TableRowProps>((
  {
  href,
  target,
  title,
  className,
  ...props
}: TableRowProps,
  ref
) => {
  const { striped, layoutMode } = useContext(TableContext);

  return (
    <TableRowContext.Provider value={{ href, target, title } as React.ContextType<typeof TableRowContext>}>
      <tr
        ref={ref}
        {...props}
        className={clsx(
          className,
          'relative w-full',
          href &&
            'has-[[data-row-link][data-focus]]:outline has-[[data-row-link][data-focus]]:outline-2 has-[[data-row-link][data-focus]]:-outline-offset-2 has-[[data-row-link][data-focus]]:outline-blue-500 dark:focus-within:bg-white/[2.5%]',
          striped ? 'odd:bg-white even:bg-zinc-50' : 'bg-white',
          href && striped && 'hover:bg-zinc-50',
          href && !striped && 'hover:bg-zinc-50',
          layoutMode?.startsWith('grid') && 'flex'
        )}
      />
    </TableRowContext.Provider>
  );
});

export function TableHeader({ className, ...props }: React.ComponentPropsWithoutRef<'th'>) {
  const { bleed, grid } = useContext(TableContext);

  return (
    <th
      {...props}
      className={clsx(
        className,
        'border-b border-b-zinc-950/10 px-4 py-2 font-bold first:pl-[var(--gutter,theme(spacing.2))] last:pr-[var(--gutter,theme(spacing.2))] dark:border-b-white/10',
        grid && 'border-l border-l-zinc-200 first:border-l-0 dark:border-l-white/5',
        !bleed && 'sm:first:pl-1 sm:last:pr-1',
      )}
    />
  );
}

export function TableCell({
  className,
  children,
  bordered = true,
  ...props
}: { bordered?: boolean } & React.ComponentPropsWithoutRef<'td'>) {
  const { bleed, dense, grid, striped, layoutMode } = useContext(TableContext);
  const { href, target, title } = useContext(TableRowContext);
  const [cellRef, setCellRef] = useState<HTMLElement | null>(null);

  return (
    <td
      ref={href ? setCellRef : undefined}
      {...props}
      className={clsx(
        className,
        'relative bg-inherit px-4 first:pl-[var(--gutter,theme(spacing.2))] last:pr-[var(--gutter,theme(spacing.2))]',
        !striped && 'border-b-zinc-200 dark:border-white/5',
        bordered && 'border-b',
        grid && 'border-l border-l-zinc-200 first:border-l-0 dark:border-l-white/5',
        dense ? 'py-2.5' : 'py-4',
        !bleed && 'sm:first:pl-1 sm:last:pr-1',
        layoutMode?.startsWith('grid') && 'flex items-center',
      )}
    >
      {href && (
        <Link
          data-row-link
          href={href as any}
          target={target}
          aria-label={title}
          tabIndex={cellRef?.previousElementSibling === null ? 0 : -1}
          className="absolute inset-0 focus:outline-none"
        />
      )}
      {children}
    </td>
  );
}
