import { useMemo } from 'react';

import { DataTableInstance } from '@/components-new/data-table/use-data-table';

/**
 * Retrieves and memoizes the rows of a data table instance based on its state and options.
 */
export const useDataTableRows = <TData>(table: DataTableInstance<TData>) => {
  const {
    getRowModel,
    getState,
    options: { data }
  } = table;

  const {
    globalFilter,
    pagination,
    sorting,
  } = getState();

  return useMemo(
    () => getRowModel().rows,
    [
      data,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
      getRowModel().rows
    ]);
};
