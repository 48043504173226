import React, { useLayoutEffect, useState } from 'react';

export const useIsOverflow = (
  ref: React.RefObject<HTMLElement>,
  callback?: (hasOverflow: boolean) => void
) => {
  const [isOverflow, setIsOverflow] = useState(false);

  useLayoutEffect(() => {
    const { current } = ref;

    if (!current) return;

    const trigger = () => {
      const hasOverflow = current.offsetHeight < current.scrollHeight || current.offsetWidth < current.scrollWidth;

      setIsOverflow(hasOverflow);

      callback?.(hasOverflow);
    };

    const observer = new ResizeObserver(trigger);
    observer.observe(current);

    trigger();

    return () => {
      observer.disconnect();
    };
  }, [ref, callback]);

  return isOverflow;
};
