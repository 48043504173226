import { ColumnDef } from '@tanstack/react-table';
import { defaultTo } from 'lodash';
import React, { useMemo } from 'react';

import { OverflowCell } from '@/components-new/overflow-cell';
import { BooleanCell, DataOnlyCell, IntegerCell,StringCell, UrlCell } from '@/features/state-reports/new/components/table-cells';
import { ColumnDefinition, ColumnDefinitionDataType } from '@/features/state-reports/new/types/state-report';

// Builds dynamic column defs for state reports.
export const useStateColumnDefs = (columnDefinitions: ColumnDefinition[]) => {
  return useMemo(() => {
    const colDefs: ColumnDef<any>[] = columnDefinitions.map(columnDefinition => {
      return {
        header: columnDefinition.header,
        accessorKey: columnDefinition.propertyName,
        cell: ({ getValue }) => render(columnDefinition, getValue()),
        enableSorting: false,
      };
    }) || [];

    return colDefs;
  }, [columnDefinitions]);
};

const render = ((columnDefinition: ColumnDefinition, value: any = '') => {
  value = defaultTo(value, '');
  const Cell = cellRendererMap[columnDefinition.dataType] ?? cellRendererMap[ColumnDefinitionDataType.STRING];
  return (
    <OverflowCell>
      <Cell value={value} columnDefinition={columnDefinition} />
    </OverflowCell>
  );
});

const cellRendererMap: { [key in ColumnDefinitionDataType]: (value: any, columnDefinition: ColumnDefinition) => React.ReactElement } = {
  [ColumnDefinitionDataType.STRING]: StringCell,
  [ColumnDefinitionDataType.DATE_ONLY]: DataOnlyCell,
  [ColumnDefinitionDataType.URL]: UrlCell,
  [ColumnDefinitionDataType.BOOLEAN]: BooleanCell,
  [ColumnDefinitionDataType.INTEGER]: IntegerCell,
  [ColumnDefinitionDataType.MULTILINE_STRING]: StringCell
};
