import clsx from 'clsx';
import React, { useRef } from 'react';

import { Tooltip, TooltipContent, TooltipTrigger } from '@/components-new/tooltip';
import { useIsOverflow } from '@/hooks/use-is-overflow';

/**
 * Renders content of a table cell and displays a tooltip when the content overflows its container.
 */
export const OverflowCell = ({ children }: { children: React.ReactNode }) => {
  const ref = useRef<HTMLDivElement>(null);
  const isOverflow = useIsOverflow(ref);

  return (
    <div className="flex items-center">
      <Tooltip placement="bottom" delay={{ open: 100, close: 100 }} disabled={!isOverflow}>
        <TooltipTrigger asChild>
          <div ref={ref} className={clsx(
            'w-full truncate',
            )}>
            {children}
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <div className="max-w-[300px] whitespace-pre-wrap">
            {children}
          </div>
        </TooltipContent>
      </Tooltip>
    </div>
  );
};
