import { useMarkdown } from '@shared/markdown';
import { createColumnHelper } from '@tanstack/react-table';
import { isEmpty, isNil } from 'lodash';
import React, { useMemo, useState } from 'react';
import { HiOutlinePaperAirplane, HiOutlinePencil, HiOutlinePlus, HiOutlineTrash } from 'react-icons/hi2';
import { LiaEdit } from 'react-icons/lia';

import { Timestamp } from '@/components/date-time';
import { Breadcrumbs } from '@/components-new/breadcrumbs';
import { Button } from '@/components-new/button';
import { ConfirmDeleteDialog, DeletableItem } from '@/components-new/confirm-delete-dialog';
import { DataPaginator } from '@/components-new/data-paginator';
import { DataTable, useDataTable } from '@/components-new/data-table';
import { EmptyState, EmptyStateBody, EmptyStateHeading } from '@/components-new/empty-state';
import { Loader } from '@/components-new/loader';
import { Overlay } from '@/components-new/overlay';
import { Page } from '@/components-new/page';
import { PageTitleRow } from '@/components-new/page-title-row';
import { RichTextPreview } from '@/components-new/rich-text-editor';
import { sendPreview, useDeletePost, usePosts } from '@/features/posts/api';
import { PostStatusBadge } from '@/features/posts/components/post-status-badge';
import { PostBase } from '@/features/posts/types/post-base';
import { notifySuccess } from '@/lib/notification/notifications';

const columnHelper = createColumnHelper<PostBase>();

const PostsPage = () => {
  const [deletableItem, setDeletableItem] = useState<DeletableItem | undefined>(undefined);
  const { toHtml } = useMarkdown();

  const {
    data: posts,
    isLoading: isLoadingPosts,
  } = usePosts();

  const {
    mutate: deletePost,
    isPending: isDeletingPost,
  } = useDeletePost();

  const handleDelete = (item: DeletableItem) => {
    deletePost(
      { postId: item.id },
      {
        onSuccess: () => {
          setDeletableItem(undefined);
        }
      }
    );
  };

  const handleSendPreview = (postId: number) => {
    sendPreview(postId).then(() => notifySuccess({ title: 'Preview sent', message: 'A preview of this notification has been sent to your email address.' }));
  };

  const columns = useMemo(() => [
    columnHelper.accessor('title', {
      header: 'Post',
      cell: info => (
        <>
          <p className="font-bold">
            {info.getValue()}
          </p>
          <RichTextPreview className="max-w-sm" content={toHtml(info.row.original.summary)} />
        </>
      )
    }),
    columnHelper.accessor('category', {
      header: 'Category',
      cell: info => info.getValue().label
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      cell: info => <PostStatusBadge status={info.getValue()}/>
    }),
    columnHelper.accessor('author', {
      header: 'Author',
      cell: info => info.getValue().label
    }),
    columnHelper.accessor('postedAt', {
      header: 'Posted At',
      cell: info => <Timestamp value={info.getValue()}/>
    }),
    columnHelper.display({
      id: 'actions',
      header: () => (
        <span className="sr-only">Actions</span>
      ),
      cell: ({ row }) => (
        <div className="flex max-w-12 justify-end">
          <Button
            plain
            title="Send Preview"
            onClick={() => handleSendPreview(row.original.id)}
          >
            <HiOutlinePaperAirplane/>
          </Button>
          <Button
            plain
            title="Edit"
            href={`/posts/${row.original.id}/edit`}
          >
            <HiOutlinePencil/>
          </Button>

          <Button
            plain
            title="Delete"
            onClick={() => setDeletableItem({
              id: row.original.id,
              name: row.original.title
            })}
          >
            <HiOutlineTrash/>
          </Button>
        </div>
      )
    })
  ], []);

  // TODO: paginate from server
  const table = useDataTable({
    data: posts ?? [],
    columns,
    enablePagination: true,
  });

  const showEmptyTableEmpty = !isLoadingPosts && (isEmpty(posts) || isNil(posts));

  return (
    <Page
      title="Posts"
      header={({ title }) => (
        <>
          <Breadcrumbs
            breadcrumbs={[
              { label: 'Posts', url: '/posts' },
            ]}
          />
          <PageTitleRow title={title}>
            <Button
              color="secondary"
              aria-label="New Post"
              href="/posts/new"
            >
              <HiOutlinePlus/>
              Add New
            </Button>
          </PageTitleRow>
        </>
      )}
    >
      <div className="mt-4">
        <div className="mt-4">
          <div className="relative mt-8 flow-root">
            {isLoadingPosts && (
              <Overlay>
                <Loader/>
              </Overlay>
            )}
            <DataTable table={table} />
            {showEmptyTableEmpty && (
              <EmptyState>
                <LiaEdit className="size-12 text-gray-500"/>
                <EmptyStateHeading>No Posts</EmptyStateHeading>
                <EmptyStateBody>
                  Click <strong>Add New</strong> to write your first post.
                </EmptyStateBody>
              </EmptyState>
            )}
            <DataPaginator table={table} className="mt-6"/>
          </div>
        </div>
      </div>

      <ConfirmDeleteDialog
        item={deletableItem}
        title="Delete Post"
        message="Are you sure you want to delete the post {name}?"
        deleteButtonTitle="Delete Post"
        isDeleting={isDeletingPost}
        onClose={() => setDeletableItem(undefined)}
        onDeleteConfirmed={handleDelete}
      />
    </Page>
);
};

export default PostsPage;
