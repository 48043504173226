import { ColumnOrderState, Row, VisibilityState } from '@tanstack/react-table';
import clsx from 'clsx';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import React, { useMemo, useState } from 'react';
import { HiDownload, HiX } from 'react-icons/hi';
import { HiMagnifyingGlass, HiViewColumns } from 'react-icons/hi2';
import { LiaEyeSlashSolid, LiaFileMedicalSolid } from 'react-icons/lia';

import { Button } from '@/components-new/button';
import { Card } from '@/components-new/card';
import { DataTable } from '@/components-new/data-table';
import { useDataTable } from '@/components-new/data-table/use-data-table';
import { EmptyState, EmptyStateBody, EmptyStateHeading } from '@/components-new/empty-state';
import { Input, InputGroup } from '@/components-new/input';
import { Loader } from '@/components-new/loader';
import { Overlay } from '@/components-new/overlay';
import { SectionDescription, SectionHeading } from '@/components-new/section';
import { TextLink } from '@/components-new/text';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components-new/tooltip';
import { ColumnConfigDialog } from '@/features/state-reports/components/column-config-dialog';
import { useStateReportData } from '@/features/state-reports/new/api';
import { StateReport } from '@/features/state-reports/new/types/state-report';
import { useStateColumnDefs } from '@/features/state-reports/new/use-state-column-defs';

type StateReportsTableProp = {
  /**
   * Currently selected state report definition.
   */
  stateReport?: StateReport;
  /**
   * Whether the user has access to this report.
   */
  canAccessReport: boolean;
};

/**
 * Configurable table for displaying state-specific report data.
 * It provides features such as:
 *
 * - Support for dynamic column definitions and visibility.
 * - Customizable column order and pinning.
 * - Filtering capabilities, including a global search input.
 * - Virtualized row and column rendering for improved performance.
 * - Export functionality to download the table data as a CSV file.
 * - User-friendly behavior, including tooltips and a loading state.
 *
 * Key Functionalities:
 * - Dynamically display data and column definitions based on the provided `stateReport` prop.
 * - Allow users to search, filter, and modify column visibility/order using the UI.
 * - Ensure accessibility and provide informative feedback when a report is unavailable or restricted.
 */
const StateReportsTable = ({ stateReport, canAccessReport }: StateReportsTableProp) => {
  const [globalFilter, setGlobalFilter] = useState('');
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const { data: stateReportData, isLoading: isLoadingStateReportData } = useStateReportData(stateReport?.key, canAccessReport);
  const columns = useStateColumnDefs(stateReport?.columnDefinitions ?? []);
  const [updatingColumnConfig, setUpdatingColumnConfig] = useState(false);

  const data = useMemo(() => (stateReportData ?? []), [stateReportData]);

  const stateReportsTable = useDataTable({
    data,
    columns,
    initialState: {
      columnPinning: { left: ['State'] },
    },
    state: {
      columnVisibility,
      columnOrder,
      globalFilter: globalFilter,
    },
    dense: true,
    striped: true,
    grid: true,
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    enableColumnResizing: true,
    enableColumnPinning: true,
    enableColumnVirtualization: true,
    enableRowVirtualization: true,
    enableGlobalFilter: true,
  });

  const hasGlobalFilter = globalFilter.length > 0;

  const csvConfig = mkConfig({
    fieldSeparator: ',',
    filename: `capsule-state-report-${stateReport?.key}`,
    columnHeaders: stateReport?.columnDefinitions.map((columnDef) => ({ key: columnDef.propertyName , displayLabel: columnDef.header })),
    boolDisplay: { true: 'Yes', false: 'No' }
  });

  /**
   * A callback function that handles the export of table rows.
   *
   * This function maps over the provided rows, processing and transforming
   * their original data into a format suitable for export.
   * It converts null values to undefined, converts 'True'/'False' strings to boolean values,
   * and retains other data as is.
   * After processing the rows, it generates a CSV file using the provided CSV configuration and triggers its download.
   */
  const onExport = (rows: Row<any>[]) => {
    const rowData = rows.map((row) => Object.keys(row.original).reduce((acc, key) => {
        if (row.original[key] === null) {
          acc[key] = undefined;
        } else if (row.original[key] === 'True' || row.original[key] === 'False') {
          acc[key] = row.original[key].toLowerCase() === 'true';
        } else {
          acc[key] = row.original[key];
        }
        return acc;
      }, {} as any));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const displayStateReportsTable = !isLoadingStateReportData && (stateReportData?.length ?? 0) > 0 && canAccessReport;
  return (
    <>
      <div className="mb-4 flex flex-col justify-between gap-4 sm:mb-8 sm:flex-row sm:items-center">
        <div>
          <SectionHeading level={2}>{stateReport?.name}</SectionHeading>
          <SectionDescription>
            {stateReport?.description}
          </SectionDescription>
        </div>
        {canAccessReport && (
          <div className="flex flex-row gap-4">
            <InputGroup>
              <HiMagnifyingGlass />
              <Input
                name="search"
                placeholder="Search&hellip;"
                aria-label="Search"
                value={globalFilter}
                onChange={(e) => setGlobalFilter(e.target.value)}
              />
              <HiX
                onClick={() => setGlobalFilter('')}
                className={clsx(
                  'transition-all duration-200 ease-in-out',
                  hasGlobalFilter && '!pointer-events-auto cursor-pointer',
                  !hasGlobalFilter && 'pointer-events-none cursor-default !text-zinc-500/50'
                )}
                aria-label="Clear search"
              />
            </InputGroup>
            <div className="flex flex-row gap-1">
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button plain onClick={() => setUpdatingColumnConfig(true)}>
                    <HiViewColumns />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  Update columns visibility and order
                </TooltipContent>
              </Tooltip>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button plain onClick={() => onExport(stateReportsTable.getRowModel().rows)}>
                    <HiDownload />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  Download as CSV
                </TooltipContent>
              </Tooltip>
            </div>
          </div>
        )}
      </div>
      {displayStateReportsTable && <DataTable table={stateReportsTable}/>}
      {isLoadingStateReportData && (
        <div className="relative min-h-20">
          <Overlay>
            <Loader message="Loading state report..."/>
          </Overlay>
        </div>
      )}
      {!isLoadingStateReportData && stateReportData?.length === 0 && (
        <EmptyState>
          <LiaFileMedicalSolid className="size-12 text-gray-500"/>
          <EmptyStateHeading>No state report data.</EmptyStateHeading>
          <EmptyStateBody>
            Please check back later.
          </EmptyStateBody>
        </EmptyState>
      )}
      {!canAccessReport &&
        <Card well>
          <EmptyState>
            <LiaEyeSlashSolid className="size-12 text-gray-500"/>
            <EmptyStateHeading>You don't have access to this report.</EmptyStateHeading>
            <EmptyStateBody>
              Please contact <TextLink href="mailto:capsule@artiasolutions.com">capsule@artiasolutions.com</TextLink> to upgrade your subscription.
            </EmptyStateBody>
          </EmptyState>
        </Card>
      }
      <ColumnConfigDialog
        table={stateReportsTable}
        open={updatingColumnConfig}
        onClose={setUpdatingColumnConfig}
      />
    </>
  );
};

export default StateReportsTable;
