import { isNil } from 'lodash';
import React, { useContext } from 'react';

export const createContextHook = <TContext extends React.Context<any>>(context: TContext) => {
  return (): NonNullable<React.ContextType<TContext>> => {
    const createdContext = useContext(context);

    if (isNil(createdContext)) {
      const contextName = context.displayName || 'Context';

      throw new Error(`[${contextName}] must be used within a ${contextName}Provider`);
    }

    return createdContext;
  };
};
