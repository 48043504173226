import * as floatingUi from '@floating-ui/react';
import { FloatingDelayGroup, FloatingPortal } from '@floating-ui/react';
import clsx from 'clsx';
import React from 'react';

import { TooltipContext, useTooltip, useTooltipContext, UseTooltipOptions } from '@/components-new/tooltip/use-tooltip';

type TooltipProps = {
  children: React.ReactNode;
  /**
   * Set the open and close delay of the tooltip, when hover or focus is removed.
   */
  delay?: UseTooltipOptions['delay'];
} & UseTooltipOptions;


/**
 * Tooltip component that establishes the root and configures the tooltip context for TooltipTrigger and Tooltip context.
 */
export const Tooltip = ({
  children,
  delay = { open: 500, close: 100 },
  ...options
}: TooltipProps) => {
  const tooltip = useTooltip({ ...options, delay });

  return (
    <FloatingDelayGroup delay={delay}>
      <TooltipContext.Provider value={tooltip}>
      {children}
      </TooltipContext.Provider>
    </FloatingDelayGroup>
  );
};

type TooltipTriggerProps = {
  /**
   * Change the default rendered element for the one passed as a child, merging their props and behavior.
   * Allows any element to be passed as the anchor.
   *
   * If `false,` a button will wrap the context for interaction states.
   */
  asChild?: boolean
} & React.HTMLProps<HTMLElement>;
export const TooltipTrigger = React.forwardRef<
  HTMLElement,
  TooltipTriggerProps
>
(({ children, asChild,...props }, forwardedRef) => {
  const context = useTooltipContext();
  const childrenRef = (children as any).ref;
  const ref = floatingUi.useMergeRefs([context.refs.setReference, forwardedRef, childrenRef]);

  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        'data-state': context.open ? 'open' : 'closed',
      })
    );
  }

  return (
    <button
      ref={ref}
      data-state={context.open ? 'open' : 'closed'}
      {...context.getReferenceProps(props)}
      {...props as any}
    >
      {children}
    </button>
  );
});

/**
 * Content of the tooltip displayed when the tooltip is open.
 */
export const TooltipContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement>
>(function TooltipContent({ style, ...props }, forwardedRef) {
  const tooltipContext = useTooltipContext();
  const ref = floatingUi.useMergeRefs([tooltipContext.refs.setFloating, forwardedRef]);

  const { isInstantPhase, currentId } = floatingUi.useDelayGroup(
    tooltipContext.context,
    { id: tooltipContext.context.floatingId }
  );

  const instantDuration = 0;
  const duration = 200;

  const { isMounted, styles } = floatingUi.useTransitionStyles(tooltipContext.context, {
    duration: isInstantPhase
      ? {
        open: instantDuration,
        // `id` is this component's `id`
        // `currentId` is the current group's `id`
        close: currentId === tooltipContext.context.floatingId ? duration : instantDuration
      }
      : duration,
    initial: () => ({
      opacity: 0
    }),
  });

  // if the tooltip isn't mounted, return nothing
  if (!isMounted) return null;

  return (
    <FloatingPortal>
      <div
        className={clsx(
          // Font styles
          'text-sm',
          // Base styles
          'isolate w-max rounded-lg px-3 py-1.5',
          // Invisible border that is only visible in `forced-colors` mode for accessibility purposes
          'outline outline-1 outline-transparent focus:outline-none',
          // Background
          'bg-white/75 backdrop-blur-xl',
          'shadow-lg ring-1 ring-zinc-950/10',
        )}
        ref={ref}
        style={{
          ...tooltipContext.floatingStyles,
          ...style,
          ...styles
        }}
        {...tooltipContext.getFloatingProps(props)}
      />
    </FloatingPortal>
  );
});
